import React from "react";

import "./style.css";

function Banner() {
  return (
    <div className="banner_wrapper">
      <div className="banner_container">
          <div className="content_head">
          <div className="headline">
          SUA EMPRESA JÁ ESTÁ PREPARADA PARA A LGPD?
        </div>
        <div className="subheadline">
          Evite que dados sensíveis de usuários sejam trafegados por e-mail e
          que sua empresa receba advertências ou multas. De acordo com a LGPD,
          as penalidades por não conformidade são avaliadas pela ANPD e podem
          incluir <strong>multas de até 2% da receita anual</strong> de uma empresa até um máximo
          de <strong> 50 milhões de reais por violação.</strong>
        </div>
          </div>

      </div>
    </div>
  );
}

export default Banner;
