import React from "react";

import "./style.css";
import ScrollAnimation from "react-animate-on-scroll";
function Content() {
  return (
    <div className="content_wrapper">
      <div className="content_container">
        <div className="content_boxes">
          <ScrollAnimation animateIn="fadeIn">
            <div className="box">
              <div className="title">
                <i class="fas fa-border-none"></i> LGPD
              </div>

              <div className="text">
                A Lei Geral de Proteção de Dados Pessoais (LGPD), pode ser
                resumida como uma lei que exige que organizações públicas e
                privadas cumpram os padrões de segurança para evitar furtos,
                vazamentos e vendas ilegais de informações digitais e
                eletrônicas.
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <div className="box">
              <div className="title">
                <i class="fas fa-border-none"></i> Lei nº 13.709
              </div>

              <div className="text">
                Na prática, trata-se da Lei nº 13.709, de 14 de agosto de 2018.
                Ela abrange o tratamento de dados pessoais, inclusive em meio
                digital, por pessoas físicas e jurídicas de direito público ou
                privado. Foi criada principalmente para proteger os direitos
                fundamentais de liberdade e privacidade.
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      <div className="content_container b">
      <ScrollAnimation animateIn="fadeIn" className="fadescroll">
        <div className="title_defualt">
          <span />
          PREVENÇÃO CONTRA VAZAMENTOS DE DADOS PESSOAIS SENSÍVEIS POR E-MAIL
        </div>
        </ScrollAnimation>
        <div className="content_boxes">
          <ScrollAnimation animateIn="fadeIn">
            <div className="box">
              <div className="title">
                <i class="fas fa-border-none"></i> Segurança da informação
              </div>

              <div className="text">
                A lei é muito clara em relação à segurança da informação. Exige
                que as empresas usem medidas técnicas e administrativas para
                proteger os dados pessoais sensíveis.
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <div className="box">
              <div className="title">
                <i class="fas fa-border-none"></i> Segurança no e-mail
              </div>

              <div className="text">
                É por isso que é importante para sua empresa evitar ataques e
                ameaças que podem levar a uma violação de dados. Um bom começo é
                adotar uma solução de proteção de e-mail, já que o e-mail é hoje
                a principal porta de entrada para ameaças..
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <div className="box">
              <div className="title">
                <i class="fas fa-border-none"></i> Módulo MAV LGPD
              </div>

              <div className="text">
                O Módulo MAV LGPD possui recursos para detectar e avisar ao
                administrador do domínio sempre que um dado sensível de um
                usuário seja trafegado no corpo de um e-mail ou em um documento
                anexado.
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <div className="box">
              <div className="title">
                <i class="fas fa-border-none"></i> Ativação do Módulo LGPD
              </div>

              <div className="text">
                Com poucos cliques você poderá ativar o módulo LGP para a sua
                empresa e começar a evitar vazamentos de dados pessoais
                sensíveis como atestado médico, CPF, CNH, telefone, PIS e outros
                dados sensíveis por e-mail.
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <ScrollAnimation animateIn="fadeIn">
        <div className="cta_wrapper">
          <a href="https://web.whatsapp.com/send?phone=553132117760&text=Ol%C3%A1,%20quero%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20m%C3%B3dulo%20LGPD%20da%20MAV,%20preciso%20falar%20com%20o%20setor%20comercial"rel="noreferrer" target="_blank">
          <button className="button">
            CLIQUE AQUI E SAIBA COMO ATIVAR O MÓDULO MAV LGPD PARA SUA EMPRESA
          </button>
          </a>
        </div>
      </ScrollAnimation>
    </div>
  );
}

export default Content;
