import React from "react";

import "./style.css";

function Footer() {
  return (
    <div className="footer_wrapper">
      <div className="footer_container">
        <div className="left">Todos os direitos reservados 2021 – © MAV Tecnologia</div>
        <div className="right"></div>
      </div>
    </div>
  );
}

export default Footer;
