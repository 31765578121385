import './App.css';

import Banner from './Components/Banner';
import Content from './Components/Content';
import Footer from './Components/Footer';
import Menu from './Components/Menu';

function App() {
  return (
    <div className="App">
      <Menu/>
      <Banner/>
      <Content/>
      <Footer/>
    </div>
  );
}

export default App;
